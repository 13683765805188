export const BASE_URL = "https://api.treatmytravel.com/api"; // Production API
// export const BASE_URL = "https://api.treatmytravel.in/api"; // Test API
// export const BASE_URL = "http://192.168.1.12:8000/api";

// ==============Admin=======================//
//Login as Admin
export const LOGIN_API = BASE_URL + "/admin/login";

//Data adding from admin panel
export const ADMIN_ADD_API = BASE_URL + "/admin/register";

//All Data for Admin
export const ADMIN_DATA_API = BASE_URL + "/admin";

//Single Data of admin by ID
export const ADMIN_DATA_BY_ID_API = BASE_URL + "/admin/";

//Update Admin data by ID
export const ADMIN_EDIT_API = BASE_URL + "/admin/";

//Delete Admin data by ID
export const ADMIN_DELETE_API = BASE_URL + "/admin/";

// Profile image upload endpoint
export const ADMIN_IMG_API = BASE_URL + "/admin_profile";

//Change password api endpoint
export const ADMIN_PASSWORD_CHANGE_API = BASE_URL + "/admin/changepassword";

// ================USER=====================================//
//For adding data from user side
export const USER_ADD_API = BASE_URL + "/user/register";

// For profile image endpoint
export const USER_IMG_API = BASE_URL + "/user_profile";

//===============Offers and Discount===========================//
// For offers and discount
export const OFFER_DISCOUNT_API = BASE_URL + "/all-offers";

export const OFFER_DISCOUNT_POST = BASE_URL + "/offers";

// For offers and discount
export const OFFER_DISCOUNT_API_BY_ID = BASE_URL + "/offers/";

export const OFFER_DISCOUNT_SINGLE_DATA = BASE_URL + "/one-offer";

// For valid coupons only
export const VALID_DISCOUNT_API = BASE_URL + "/valid_offers";

// ============Popular Destination==========================//
// For Popular destination - POST and GET method
export const POPULAR_DESTINATION_API = BASE_URL + "/popular_dest";

// For Popular destination - DELETE method
export const POPULAR_UPDATE_API = BASE_URL + "/popular_dest/";

// For Popular destination - DELETE method
export const POPULAR_DELETE_API = BASE_URL + "/popular_dest/";

// for popular destination -single data method post
export const POPULAR_DESTINATION_SINGLE_DATA = BASE_URL + "/popular_dest/";

// ==============Flight Logo=======================================//
// For Flight Logo
export const ADD_FLIGHT_LOGO_API = BASE_URL + "/add_flight_logo/";

// For Flight Logo
export const GET_FLIGHT_LOGO_API = BASE_URL + "/flight_logo/";
//web management

//get...post
export const Term_Use = BASE_URL + "/term_of_use";

//update...delete
export const UPDATE_Term_Use = BASE_URL + "/term_of_use/";

export const Term_Services = BASE_URL + "/term_of_service";
export const UPDATE_Term_Services = BASE_URL + "/term_of_service/";
//get...post
export const Privacy_Cookie = BASE_URL + "/privacy_policy";
//update...delete
export const Privacy_Cookie_UPDATE = BASE_URL + "/privacy_policy/";

//get...post
export const Payment_Security = BASE_URL + "/payment_security";
//update...delete
export const Payment_Security_UPDATE = BASE_URL + "/payment_security/";

// ==============Flight Logo=======================================//
// For Admin Order
export const ORDER_LIST_API = BASE_URL + "/get-admin_order";

//Single Data of orderdetails by ID
export const ORDER_LIST_API_BY_ID = BASE_URL + "/admin_single_order";

// For Admin Sector
export const SECTOR_LIST_API_BY_ID_SINGLE = BASE_URL + "/admin_sector_by_id";

export const SECTOR_LIST_API_BY_ORDERIDF = BASE_URL + "/admin_single_sector";

//faq
export const FAQ_GET = BASE_URL + "/faq";
export const FAQ_SINGLE_DATA = BASE_URL + "/faq/";
export const FAQ_POST = BASE_URL + "/faq";
export const FAQ_UPDATE_DATA = BASE_URL + "/faq/";

//cms
export const GET_POST_DATA_CMS = BASE_URL + "/cms";
// export const CMS_TYPES =BASE_URL +"/cmsType"
export const UPDATE_DELETE_CMS = BASE_URL + "/cms/";
export const SINGLE_CMS = BASE_URL + "/single_cms";

// For Admin Passenger
export const PASSENGER_LIST_API_BY_ID_SINGLE =
  BASE_URL + "/admin_passenger_by_id";

// For Admin Single Order Passenger
export const PASSENGER_LIST_API_BY_ORDERIDF =
  BASE_URL + "/admin_single_passenger";

//flight order data download
export const EXPORT_CSV_DATA = BASE_URL + "/exportCsv";

//ORDER DATA
export const ORDER_DATA = BASE_URL + "/orderSum";

//TRANSACTION HISTORY
export const TRANSACTION_HISTORY = BASE_URL + "/transaction";

// USER WALLET
export const USER_WALLET = BASE_URL + "/wallet_details";

// USER SINGLE WALLET DATA
export const SINGLE_USER_WALLET = BASE_URL + "/single_wallet";

// ORDER DATA FOR AMMENDMENT
export const AMEND_ORDER = BASE_URL + "/orderData";

export const GET_AMEND_DETAILS_ONLY = BASE_URL + "/amendment/charge";
export const SUBMIT_AMMENDMENT = BASE_URL + "/amendment/submit";
export const AMMENDMENT_DETAILS = BASE_URL + "/amendment/details";

// ACCOUNT BALANCE DETAILS OF ADMIN FROM TRIPJACK
export const ACCOUNT_DETAILS = BASE_URL + "/user_details";

// REFUND API
export const REFUND_LIST = BASE_URL + "/refund_list";
export const REFUND_PROCESS = BASE_URL + "/refund_user";
export const PHONEPE_REFUND = BASE_URL + "/phonepe_refund";

// refund feild
export const REFUND_FAILED = BASE_URL + "//refund-failed";

// FLIGHT LOGOS
export const FLIGHT_LOGOS = BASE_URL + "/flight_logo";
export const ADD_FLIGHT_LOGOS = BASE_URL + "/add_flight_logo";

// holiday packages apis

export const All_HOLIDAY_PACKAGES = BASE_URL + "/holiday-list";
export const DATA_EXPORTS = BASE_URL + "/exports";
export const TICKES_DOWNLOAD = BASE_URL + "/admin-ticket-print";

// airport End points
export const AIRPORT_DATA = BASE_URL + "/suggest-airport";
export const SINGLE_AIRPORT = BASE_URL + "/suggest-airport/";

// auto search 
export const AIRPORT_SEARCH = BASE_URL + "/airport-auto-suggest";
